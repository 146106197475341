@import '../../../../../../assets/styles/variables.scss';
.add-btn , .remove-btn {
    font-weight: 700;
    color: $primary;
}

.remove-btn {
    color: $danger;
}

