@import "../../../../../../assets/styles/variables.scss";

.buttonStyle{
    display: flex;
    gap: 20px;
}
.label{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: $font-Instrument;
    color: $dark10;
}
.value{
    font-size: 14px !important;
    line-height: 16px;
    font-weight: 500 !important;
    font-family: $font-Instrument;
    color: $dark14;
    margin-bottom: 10px;
}
.terms{
    font-size: 18px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
    margin-top: 20px !important;
    font-family: $font-Instrument;
}