.input {    
    display: flex;
    align-items: center;
                    
    border-radius: 4px;
    border: 1px solid #dee3e7;
    font-size: 14px;
    transition: box-shadow 0.2s ease;
    font-weight: 500 !important;
    color: #181f39 !important;
    height: 46px;
    justify-content: center;
    padding-top: 12px;

    svg{
        fill: #555F6D;
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
}

