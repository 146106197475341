@import "../../../assets/styles/variables.scss";
div:is(.rs-picker-popup) {
  z-index: 0;
}

.rs-date-range-input:not(:placeholder-shown){
text-transform: uppercase !important;
}

.rs-picker-daterange > .rs-input-group.rs-input-group-inside .rs-input {
  padding-right: 0;
  padding-top: 4px;
  height: 44px;
  padding-bottom: 4px;
}

.rs-picker-daterange-menu {
  &.report-data-picker-container-dashboard {
    left: auto !important;
    right: 8vw !important;
  }
  .rs-picker-menu {
    .rs-calendar {
      min-width: 241px !important;
    }
  }
  .rs-stack-item {
    padding: 5px !important;
  }
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  top: 0px !important;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 5px !important;
}

.rs-stack {
  margin-top: 7.5px !important;
  .rs-picker-toggle-placeholder {
    color: hsl(0, 0%, 50%) !important;
    font-size: 14px !important;
    transition: box-shadow 0.2s ease !important;
    font-weight: 500 !important;
  }
}

.charts-datapicker-container {
  .rs-picker-toggle {
    .rs-stack {
      margin-top: 0px !important;
    }
  }
}
// .rs-anim-fade,
// .rs-anim-in,
// .rs-picker-popup-daterange,
// .rs-picker-popup,
// .placement-bottom-start {
//   right: 77px !important;
//   left: auto !important;
// }

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-btn-primary {
  background-color: $primary !important;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  color: $primary !important;
  background-color: $bgLight !important;
}

.rs-picker-popup
  .rs-calendar-table-cell:focus-visible
  .rs-calendar-table-cell-content {
  outline: none !important;
}

.rs-calendar-table-cell-in-range::before {
  background-color: $bgLight !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px $primary !important;
}

.rs-btn-link {
  color: $primary !important;
}

.rs-input-group{
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}

.rs-picker-error > .rs-picker-input-group{
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.rs-picker-header-date{
  text-transform: uppercase !important;
}
.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  border-color: $primary !important;
  outline: 3px solid $bgLight !important;
  border-radius: 5px;
}

.rs-calendar-table-cell-selected{
  .rs-calendar-table-cell-day{
    color: #fff !important;
  }

  .rs-calendar-table-cell-day:hover{
    color: #000 !important;
  }
}

.header-daterange {
  .rs-picker-daterange > .rs-input-group.rs-input-group-inside .rs-input {
    padding-right: 0;
    padding-top: 5px;
    height: 40px;
    padding-bottom: 5px;
  }
}
@media (min-width: 200px) and (max-width: 900px) {
  .rs-picker-popup {
    scale: 0.8;
    transform-origin: top left;
  }
}
