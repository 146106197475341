.primitive-dropdown{
    .dropdown-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        border-bottom: 1px solid #ccc;
        // min-width: 120px;
    
        font-size: 14px;
        color: black;
        padding: 2px 5px;
        svg {
          width: 18px;
          height: 18px;
        }
    
        .dropdown-item:active {
          color: white;
        }
      }
}