@import "../../../../../../assets/styles/variables.scss";

.tableWrap{
    width: 25%;
    border: solid 1px $dark6;
    margin-bottom: 1rem;

    table {
        margin-bottom: 0;
    }
}
.info_history_wrap{
    display: flex;
    gap: 20px;

    tr {
        td {
            width: 50%;
            font-size: 12px;
        }
        th {
            font-size: 12px;
        }
    }
}


.history_wrap{
    width: 75%;
    height: auto;
    overflow: scroll;
    max-height: 67vh;
}

.value, .label {
    font-size: 11px;
} 

.value{
    font-weight: 600;
}



.tabelRowStyleWrap{
    font-size: 16px;
}
.CustomButtonWrap{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
.autoWrap{
    display: flex;
    gap: 10px;
}
.tableWrapRight{
    overflow: scroll;
    width: 100%;
}
.obligorduetable{
    // cursor: pointer;
    position: relative;
    

}
.wordBreak{
    white-space: break-spaces;
}