.error_div{
    color: var(--Ab-16, #BE0F00);
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
    display: flex;
}

.small_error{
    font-size: 12px;
    margin-top: 3px;
}