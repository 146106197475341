@import '../../../assets/styles/variables.scss';

.breadcrumb{
    svg{
        width: 20px;
    }
    margin-bottom: 6px !important;
}

.breadcrumb-item,.breadcrumb-link{
    font-size: 14px;
    line-height: 18px;
}

.breadcrumb-item{
color: $dark10;;
}

.breadcrumb-link {
    color:  $dark14;
}
