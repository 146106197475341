.custom-option {
    display: flex;
    justify-content: space-between;
  }

  .custom-option .tag {
    background-color: #fff;
    border-radius: 30px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 508;
    margin-left: 10px;
    height: 22px;
    color: #3B424A;
  }
  