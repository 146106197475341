.password_strength {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.condition_wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.condition {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #ccd1df;
  transition: background-color 0.3s;
}

.condition.met {
  background-color: #83bf6e !important;
}

.conditions_not_met .condition {
  background-color: #be0f00;
}

.condition_heading {
  color: #1e273b;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 0;
}

.condition_description {
  color: #82899a;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
}
