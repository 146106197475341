@import '../../../assets/styles/variables.scss';

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $dark14 !important;
}

.h1 {
  font-size: 28px;
  margin: 10px 0;
}
.h2 {
  font-size: 26px;
  margin: 5px 0;
  font-weight: 600 !important;
}

.h3 {
  font-size: 24px;
  margin: 10px 0;
}

.h4 {
  font-size: 22px;
  margin: 10px 0;
}

.h5 {
  font-size: 20px;
  margin: 10px 0;
  font-weight: 600 !important;
}

.h6 {
  font-size: 18px;
  margin: 10px 0;
}
