@import "../../../../../assets/styles/variables.scss";
.terms{
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 20px;
    font-family: $font-Instrument;
}
.label{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: $font-Instrument;
    color: $dark10;
}
.value{
    font-size: 14px !important;
    line-height: 16px;
    font-weight: 500 !important;
    font-family: $font-Instrument;
    color: $dark14;
}
.fundingDetails{
    display: flex;
    border: 1px solid $dark6;
    padding: 12px;
}
.buttonStyle{
    width: 100%;
    height: 46px;
}

.headerStyleCell{
    padding: 5px;
  }
  .columnCellStyle{
    padding: 5px;
  }