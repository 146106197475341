@import '../../../assets/styles/variables.scss';

.tag-input-container {
  position: relative;
  width: 100%;
}

.floating-label-container {
  position: relative;
  width: 100%;
}

.floating-label {
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  pointer-events: none;
  color: #555f6d;
}

.floating-label.focused {
  top: 10px;
  left: 16px;
  font-size: 12px !important;
  color: #555f6d;
}

.tag-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #dee3e7;
  border-radius: 4px;
}

.tag {
  display: flex;
  align-items: center;
  background-color: #f1f3f5;
  line-height: normal;
  border-radius: 3px;
  margin: 2px;
  font-size: 14px;
  color: #181f39;
  z-index: 3;
  padding: 2px 4px;
}

.tag-error {
  border:1px solid #f8d7da;
  color: #721c24;
  background-color: #fff;

  svg {
    fill: #721c24;
  }
}

.tags-container {
  padding-left: 13px;
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

.remove-button {
  background: none;
  border: none;
  cursor: pointer;
  svg {
    width: 9px;
    height: 9px;
    margin-left: 8px;
  }
  line-height: 0.5;
}

.tag-input-field {
  border: none;
  flex: 1;
  padding: 4px 14px;
  outline: none;
  padding-top: 16px;
  padding-right: 2px;
  width: auto;
  min-width: 20px;
  padding-left: 4px;
  background: white;
}

.tag-input-field:focus {
  border: none;
}

.tag-input-field:focus {
  outline: none;
}

.focused-tag-input {
  border: 1px solid $primary;
}
