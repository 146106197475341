.inputWrap {
  width: 100%;
}
.inputField {
  width: 100%;
  height: 40px;
  padding: 5px;
  background-color: #fff;
}
.dropdownWrap {
  padding: 10px;
  border: 1px solid #dedede;
  background-color: #fff;

  border-radius: 4px;
  &:focus {
    border: 1px solid #146ef5;
  }
  outline: none;
}
