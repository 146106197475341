$white: #ffffff;
$light: #efefef;
$lightestGrey: #f4f4f4;
$bgLight: #fbfcfd;
$bgDark: #111112;
$grey0: #c0c4c9;
$grey: #6f767e;
$lightGrey: #ced5de;
$dark: #1B242C;
$green: #1CA076;
$danger: #be0f00;
$yellow: #ffa700;
$side_grey: #dee3e7;
$primary: #146ef5;
$dark: #181f39;
$dark10: #555f6d;
$dark12: #3b424a;
$dark11 : #4A545E;
$dark14: #1b242c;
$dark6: #DEE3E7;

$font-Instrument: 'Instrument Sans';

.fw-bold {
  font-weight: 500 !important;
}

.text-success {
  color: $green !important;
}

.text-dark {
  color: $dark !important;
}

.text-error {
  color: $danger !important;
}

.text-warning {
  color: $yellow !important ;
}
