.duesWrap{
    display: flex;
    gap: 10px;
}
.clientBasicInfo{
    width: 30%;
}
.tableInfoWrap{
    width: 70%;
    td{
        min-width: 120px;
    }
}
