.ag-root-wrapper {
  border: none !important;
  border-radius: 0;
  margin: 20px 0;
}
.ag-header-cell-resize,.ag-sort-indicator-container{
  display: none;
}
.ag-header-cell-text {
  font-weight: 600;
  color: #555f6d;
  font-size: 10px;
  white-space: wrap;
}
.ag-header-cell{
  padding: 0 0 0 5px !important;
  &:hover{
    .ag-sort-indicator-container{
      display: block;
      margin-bottom: 15px;
    }
  }
}
.ag-header{
  border-bottom: none !important;
}
.ag-header-container{
padding: 11px 0 11px 1px !important;
}
.ag-header-cell-label {
  padding: 0 0 0 5px !important;
}
.ag-header-viewport {
  border-bottom: 1px solid rgba(224,224,224,1) !important;
  overflow: hidden;
  &:hover{
    .ag-header-cell-resize{
      display: block;
    }
  }
}
.ag-cell {
  color: #3b424a;
  font-size: 11px;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap !important;
  align-content: center;
  line-height: normal !important;
  padding: 0 0 0 5px !important;
  // justify-content: center;
  span,div{
    color: #3b424a;
    font-size: 11px;
    white-space: wrap !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap !important;
    align-content: center;
    line-height: normal !important;
    padding: 0 0 0 5px !important;
    // min-height: 52px;
  }
}
.ag-header-container {
  background-color: #f1f3f5;
  padding: 11px;
}
.ag-row {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  background-color: #fff !important;
  &:last-child{
    border-bottom: none !important;
  }
}
.ag-overlay,.ag-body-viewport{
  min-height: 31.25rem;
}
.ag-container-small{
  overflow: hidden !important;
  .ag-overlay,.ag-body-viewport{
    min-height: 280px;
    height: 280px;
    overflow: hidden;
  }
  .ag-center-cols-container{
    min-height: 280px;
    height: 280px !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

// .ag-row-even{
//   min-height: 52px;
//   // display: flex;
//   // align-items: center;
//   // justify-content: center;
//   // transform: translateY(52px) !important;
//   // height: 52px !important;
// }
// .ag-row-odd{
//   min-height: 52px;
//   // transform: translateY(52px) !important;
//   // display: flex;
//   // align-items: center;
//   // justify-content: center;
//   // height: 52px !important;
// }

// .ag-body-viewport{
//   min-height: 31.25rem;
// }

.ag-cell-focus:not(.ag-cell-range-selected):focus-within{
  border: none;
  border-color: none;
  border-style: none;
  outline: none;
}
.ag-row-hover{
  &::before{
    display: none !important;
  }
  &:hover{
    background: #dee3e740 !important;
  }
}

@media (min-width:1550px){
.ag-header-cell-text {
font-size: 11px;
}
}

.ag-header-cell-comp-wrapper{
  align-items: center;
  justify-content: center;
  // border-left: 1px solid black;
}