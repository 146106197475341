.custom-menu{
    span {
        color: #3b424a !important;
    }
    // svg{
    //     fill: #3b424a !important;
    // }

    i{
        display: flex;
        width: 18px;
        margin-right: 5px;
    
        // svg{
        //     fill: #3b424a !important;
        // }

    }
}

