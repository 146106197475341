.duesWrap{
    display: flex;
    gap: 10px;
}
.clientBasicInfo{
    width: 30%;
}
.tableInfoWrap{
    width: 70%;

    td{
        min-width: 300px;
    }
}
.ButtonWrap{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
}