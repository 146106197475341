.input_icon {
  position: absolute;
  left: 15px;
}
.input_icon_wrap {
  position: relative;
  .primary-input {
    padding-left: 40px;
  }
}
