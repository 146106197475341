@import "../../../../../assets/styles/variables.scss";

.transStyle {
  display: flex;
}
.fundedViewleftWrap {
  width: 36%;
  border: solid 1px $dark6;
  margin-top: 1rem;
  padding: 0 10px;
}

th{

  color: #181f39 !important;
  div{
    color: #181f39 !important
  }
}
.label {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  font-family: $font-Instrument;
  color: $dark10;
}
.value {
  font-size: 11px !important;
  line-height: 16px;
  font-weight: 500 !important;
  font-family: $font-Instrument;
  color: $dark14;
}
.valueWrap {
  border-top: solid 1px $dark6;
  border-bottom: solid 1px $dark6;
  display: flex;
  align-items: center;
}
.valueWrapBotton {
  // border-top: solid 1px $dark6;
  border-bottom: solid 1px $dark6;
  display: flex;
  align-items: center;
}
.buttonStyle {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.bothsidewrap {
  display: flex;
  gap: 20px;
}
.label_quill{
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 8px 0px !important;
}
.notes_container{
  height: 718px;
  overflow: scroll;
  width: 50%;
  margin-top: 10px;
}
.dateButtonWrap{
  display: flex;
  gap: 15px;
}