@import 'Typography.scss';
@import 'inputs.scss';
@import 'variables.scss';
@import 'button.scss';
@import 'anchor.scss';

//common
.HeadingStyle {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-family: $font-Instrument;
  color: $dark !important;
}
.HeadingSubStyle{
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  font-family: $font-Instrument;
}
.addHeaderStyle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #1B242C !important;
}
.headingSearchWrap {
  display: flex;
  justify-content: space-between;
}
.valueStyle {
  font-size: 11px;
  font-weight: 400;
  font-family: $font-Instrument;
  color: #1b242c;
}
.searchButton {
  display: flex;
  align-items: center;
  gap: 20px;
}

.buttonWrap {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.resetAdd {
  display: flex;
  gap: 20px;
}

// Toast React Hot Toast Customization
.go2344853693:after {
  border-color: #1e273b !important ;
}

.go2534082608:after,
.go2534082608:before {
  background: rgb(190, 15, 0) !important;
}

.go2344853693:after {
  border-color: #83bf6e !important;
}

.go2344853693,
.go2534082608 {
  background-color: #fff !important;
}

::-moz-selection {
  /* Code for Firefox */
}

::selection {
}

//view
.view-label {
  font-size: 12px;
  font-weight: 400;
  color: $dark10;
  margin-bottom: 0;
}

.view-data {
  font-size: 14px;
  color: $dark14;
  font-weight: 500;
}

.flex-1{
  flex: 1;
}


.checkbox_container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: min-content;
  position: relative;
  label {
    width: max-content;
    margin-bottom: 3px;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #1B242C;
    display: flex;

  }
  input {
    width: 20px;
    height: 20px;
  }
}

.MuiDataGrid-filler{
  display: none !important;
}

.MuiDataGrid-cell {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  text-transform: capitalize !important;
  white-space: pre !important;
  display: flex;
  align-items: center;
  line-height: normal !important;
  div{
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
    white-space: pre;
  }
}

.required-field::after {
  content: "*";
  color: $danger;
  padding-left: 3px;
}

.no_capitalize{
  text-transform: none !important;
}
h1,h2,h3,h4,h5,h6{
  color: #1B242C !important;
}
.custome{
  margin-left: 10px;
}

.fw-semi{
  font-weight: 600;
}

.pre{
  white-space: pre;
}

div:title{
  text-transform: capitalize;
}

.fixed-part{
  position: sticky;
    top: 0;
    padding-top: 24px;
    z-index: 1054;
    background-color: white;
    margin-top: -24px;
}

.tab-table {
  min-height: 83vh;
  display: flex;
  flex-direction: column;
}

@media screen and (min-height: 600px) and (max-height: 799px) {
  .tab-table {
    min-height: 81vh;
  }
}

@media screen and (min-height: 800px) and (max-height: 999px) {
  .tab-table {
    // min-height: 84vh; -- removed cause of scroll issue in Funding Due
    min-height: 82vh;
  }
}

@media screen and (min-height: 1000px) {
  .tab-table {
    min-height: 88vh;
  }
}
.FundingStatus{
  scale: 0.75;
}



.MuiDataGrid-main{
  div::-webkit-scrollbar{
    display: none;
  }
}

.responsive-graph-height {
  height: 700px !important;  /* Default height */
  width: 100% !important;
}

@media (max-height: 750px) {
  .responsive-graph-height {
    height: 500px !important; /* Height for screens shorter than 800px */
  }
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3 !important;
}

.line-truncate {
  display: -webkit-box; /* Establishes the box for truncation */
  -webkit-line-clamp: 3; /* Limits the content to 3 lines */
  -webkit-box-orient: vertical; /* Sets the orientation to vertical */
  overflow: hidden; /* Ensures content beyond 3 lines is hidden */
  text-overflow: ellipsis; /* Adds ellipsis for overflow content */
  white-space: wrap !important; /* Allows wrapping of text */
}

.wrap-text {
  white-space: normal !important;
  word-wrap: break-word !important;
}
.word-break-global{
  word-break: break-all;
}

.MuiDataGrid-cell{
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap !important;
  align-content: center;
  div,span{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap !important;
    align-content: center;
  }
}
.ag-column-first.ag-header-parent-hidden{
    left: 15px !important;
}