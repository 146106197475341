@import "../../../../../../assets/styles/variables.scss";

.buttonStyle{
    display: flex;
    gap: 20px;
    margin-top: 1.2rem;
}
.label, .value{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: $font-Instrument;
    color: $dark14;
    margin-bottom: 10px;
}
.value{
    font-weight: 600 !important;

}
.terms{
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-top: 12px !important;
    font-family: $font-Instrument;
    color: $dark14;

}