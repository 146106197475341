.AddSignerStyle{
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}
.buttonWrap{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.resetAdd{
    display: flex;
    gap: 20px;
}