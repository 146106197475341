@import '../../../../assets/styles/variables.scss';

.HeadingStyle {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-family: $font-Instrument;
}
.headingSearchWrap {
  display: flex;
  justify-content: space-between;
}
.valueStyle {
  font-size: 11px;
  font-weight: 400;
  font-family: $font-Instrument;
}

.EditStyle {
  padding-left: 10px;
}
.viewEdit {
  display: flex;
  align-items: center;
}
.searchButton {
  display: flex;
  align-items: center;
  gap: 20px;
}
.buttonWrap {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.resetAdd {
  display: flex;
  gap: 20px;
}
.label_view{
  color:  #555F6D;
  font-size: 14px !important;
  font-weight: 400 !important;
  min-width: max-content;
}
.label_description{
  color: #1B242C;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.type_header_styles{
  border-bottom: 2px solid var(--Brand-Color, #146EF5);
  color: #146EF5;
  padding: 12px 16px 12px 16px;
}
.type-header{
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 2px solid #D5D5D5;
  padding-bottom: 10px;
}
.client_type_main{
  margin-bottom: 15px;
}

.duesWrap{
  display: flex;
  gap: 20px;
}
.clientBasicInfo{
  width: 30%;
}
.tableInfoWrap{
  width: 70%;
}