.excel-parsing-table{
    width: 100%;
    overflow: scroll;
}

.excel-parser {
    background-color: #f9f9f9;
    margin: 20px auto;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow: scroll;

    tr td {
        width: auto;
    }

    .header {
        text-align: center;
        color: #333;
        margin-bottom: 20px;
      }
      
      .sheet-container {
        margin-bottom: 20px;
      }
      
      .sheet-name {
        font-size: 18px;
        color: #555;
        margin-bottom: 10px;
      }
      
      .data-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 10px;
        background-color: #fff;
      }
      
      .data-table th,
      .data-table td {
        border: 1px solid #ddd;
        text-align: left;
        padding: 8px;
        font-weight: 500;
      }
      
      .data-cell {
        background-color: #fafafa;
      }
      
      .data-table tr:nth-child(even) {
        background-color: #f2f2f2;
      }
      
      .data-table tr:hover {
        background-color: #ddd;
      }
      
      .error-message {
        color: red;
        text-align: center;
        font-weight: bold;
      }
      
      .loading-message {
        color: #555;
        text-align: center;
        font-style: italic;
      }
  }

  .loading-message{
    margin-top: 20px;
  }
  

  