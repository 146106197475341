* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #3b424a;
  font-family: 'Instrument Sans', sans-serif;
}

body{
  overflow: hidden;
}

.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-28 {
  font-size: 28px !important;
}

.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-bold {
  font-weight: bold;
}

.d-flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}

.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  gap: 12px;
}
.gap-4 {
  gap: 16px;
}
.gap-5 {
  gap: 20px;
}

.mb-1 {
  margin-bottom: 4px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-5 {
  margin-bottom: 20px;
}
.mb-6 {
  margin-bottom: 24px;
}

.ml-1 {
  margin-left: 4px;
}
.ml-2 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-4 {
  margin-left: 16px;
}

.ml-5 {
  margin-left: 20px;
}

.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mr-4 {
  margin-right: 16px;
}
.mr-5 {
  margin-right: 20px;
}

.mt-1 {
  margin-top: 4px;
}
.mt-2 {
  margin-top: 8px;
}
// .mt-3 {
//   margin-top: 12px;
// }
.mt-4 {
  margin-top: 16px !important;
}
.mt-5 {
  margin-top: 20px;
}
.mt-6 {
  margin-top: 24px;
}

.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-30 {
  width: 32%;
}

.fw-500{
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}
.pointer-none {
  pointer-events: none;
}

table {
  font-size: 11px;
}
