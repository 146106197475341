.clamp-text {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .view-more{
    font-weight: 600;
    cursor: pointer;
    color: #146ef5;
  }