@import '../../../assets/styles/variables.scss';

.checkbox-input {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid $side_grey;
  border-radius: 2px;
  background-color: $white;
  cursor: pointer;
  position: relative;
  outline: none;
  transition: background-color 0.3s, border-color ease 0.3s;
}

.checkbox-input:checked {
  background-color: $primary;
  border-color: $primary;
}

.checkbox-input:checked::after {
  content: '';
  position: absolute;
  top: 3px; /* Positioning for the tick mark */
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid white; /* White color for the tick */
  border-width: 0 3px 3px 0; /* Create the tick shape */
  transform: rotate(45deg); /* Rotate to form the tick */
  border-radius: 1px;
}

.checkbox-input:hover {
  border-color:  $primary;
}

.error {
  color: $danger;
  font-size: 12px;
  margin-top: 4px;
}
