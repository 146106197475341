@import "../../../assets//styles/variables.scss";

.image_selector {
  border: 1px dashed #dee3e7;
  padding: 11px 14px;
  font-size: 16px;
  padding-bottom: 4px;
  background-color: white;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  min-height: 46px;
  display: flex;
  align-items: center;
}

.custom_ui {
  display: flex;
  gap: 10px;
  align-items: center;


  svg{
    fill: #555F6D;
    width: 16px;
    height: 16px;
  }
}

.placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #555F6D;
}
.labelStyle {
  cursor: pointer;
  padding-left: 10px;
}

.image_name{
    font-size: 14px;
    font-weight: 500;
}
