.dateRange {
  cursor: pointer !important;
  border-radius: 5px !important;
  :global(.rs-picker-toggle) {
    cursor: pointer !important;
  }
}
.dateRangeWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reportName {
  font-size: 20px;
  font-weight: 700;
}
// .dateRangeShortCut {
//   padding-right: 17px;
// }


