@import '../../../assets/styles/variables.scss';
.info_div{
    color: $dark11;
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    display: flex;

    svg{
        fill : #ffa700;
        width: 13px;
        height: 13px;
        line-height: normal;
        margin-right: 6px;
    }
}

.small_info{
    font-size: 11px;
    margin-top: 3px;
}