@import './variables.scss';

.btn-xl {
  height: 48px;
}

.btn-smlg{
  height: 45px;
  border-radius: 6px;
}

.btn-lg{
  height : 44px;
  border-radius: 6px;
  min-width: 120px;
  padding : 12px 0;
  line-height: normal;
}
.btn-sm{
  height : 44px;
  border-radius: 6px;
  min-width: 50px;
  padding : 12px 0;
  line-height: normal;
}

.btn {
  border-radius: 6px;
  padding: 14px 0;
}

.btn-primary {
  background: $primary;
  color: $white;

  font-weight: 600 !important;
  font-size: 16px;
  border: none;
}

.btn-outline-dark {
  background: none;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid $dark12;
}
.btn-outline-primary {
  background: none;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid $primary;
  color: $primary;
}

.btn-outline-grey {
  background: none;
  padding: 14px 0;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid $side_grey;
  color: $dark10;
  padding: 14px 0;
  border-radius: 6px;
}
.iconRequiredStyle{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 5px;
  // :hover{
  //   svg, path{
  //     fill: white
  //   }
  // }
}