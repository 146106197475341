.ql-toolbar.ql-snow {
    border: 1px solid var(--Dark-6, #DEE3E7);
    border-radius: 12px 12px 0 0 !important;
    background-color: hsl(0, 0%, 97%) !important;
}

.ql-container.ql-snow{
    border-radius: 0 0 12px 12px !important;
    border: 1px solid var(--Dark-6, #DEE3E7);
}
.ql-snow .ql-editor a {
    text-decoration: underline !important; 
}
.label-quill{
    font-size: 16px !important;
    font-weight: 600 !important;
    margin: 8px 0px !important;
}
.prior-viewer{
    width: 49%;
    border: 1px solid #dee3e7 !important;
    // border-radius: 8px;
    padding: 10px;
    overflow-wrap: anywhere;
    overflow: scroll;
    background: #F5F7F9;
    border-radius: 6px;
    overflow-x: hidden;
    // h1,h2,h3,h4,h5,h6,p,ol,ul{
    //     background: #FFFFFF;
    //     box-shadow: 0px 1.5px 4px -1px #0A090B12;
    //     padding: 10px;
    //     border-radius: 2px 6px 6px 6px;
    //     max-width: fit-content;
    //     }
}