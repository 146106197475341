@import "../../../assets/styles/variables.scss";
.heading {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: $dark;
  font-size: 28px;
  font-weight: 700;
}

.subtitle {
  color: #1e273b;
  font-size: 15px;
  font-weight: 400;
}

.description {
  color: $dark;
  font-size: 15px;
  font-weight: 400;
}
