.label,
.value {
  font-size: 11px;
  border: 1px solid #dee3e7;
  padding: "8px";
  font-weight: 400;
}


.top_header {
  border-left: 1px solid #dee3e7;
  border-top: 1px solid #dee3e7;
  border-right: 1px solid #dee3e7;
  padding: "8px";
}

.value {
  font-weight: 600;
}

tr:first-child {
  width: 40%;
}
