.icon-circle{
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 50%;
    color: #374957;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-circle-icon { 
    color: #374957;
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-size: 10px;
    font-weight: 600;
    line-height: 20px; /* 200% */
}


.icon-circle{
    width : 48px;
    height: 48px;
    border-radius: 24px;
    cursor: pointer;
}

.icon-circle-icon{
    font-size: 18px;

    img {
        width: 30px;
        height: 30px;
    }

}
