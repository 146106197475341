.scroll-wrapper {
    position: relative;
  }
  
  .scroll-container {
    overflow: auto;
    white-space: nowrap;
  }
  
  .scroll-arrow {
    position: absolute;
    top: 17px;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    cursor: pointer;
    z-index: 1000;
    border-radius: 50%;
    visibility: hidden;
    padding-top: 1px;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .scroll-arrow.show {
    visibility: visible;
    opacity: 1;
  }
  
  .scroll-arrow.left {
    left: 5px;
    padding-right: 3px;
  }
  
  .scroll-arrow.right {
    right: 5px;
    padding-left: 1px;
  }
  