.page_header,
.small_page_header {
  font-weight: 700;
  font-size: 28px;
  color: "#1B242C";
  line-height: 48px;
}

.small_page_header {
  font-size: 24px;
  font-weight: 650;
}
