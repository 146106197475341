@import "../../../assets/styles//variables.scss";

.pagination-component {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagination-component-not {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.pagination-component .show-results {
  display: flex;
  gap: 12px;
  align-items: center;
}

.pagination-component .result {
  font-size: 14px;
}

.pagination-component .dropdown-box {
  border-radius: 8px;
  border: 1px solid var(--50, #c0c4c9);
  width: 60px;
  height: 40px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  i {
    font-size: 16px;
  }
}

.pagination-component .dropdown-menu {
  min-width: 60px;
}

.pagination {
  gap: 12px;
  margin-bottom: 0;

  .active > .page-link,
  .active > .page-link:hover {
    background-color: $primary !important;
    border-color: $primary !important;
    font-weight: 600;
    color: #fff;
    box-shadow: none;
    outline: none;
  }
  .page-link:focus{
    box-shadow: none;
  }
  .page-link{
    color: #333;
    background-color: #F6F9FF;
  }
  .page-item .circular-button:first-child{
    background-color: #fff;
  }
  .page-item .circular-button:last-child{
    background-color: #fff;
  }
}

.page-link,
.page-link:hover,
.page-link:active,
.page-link:focus {
  color: $dark;
  box-shadow: none;
}

.circular-button {
  min-width: 40px;
  height: 40px;
  border-radius: 22% !important;
  background-color: #f4f4f4;
  font-weight: 600;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: none;
}

.side {
  border: 1px solid #c0c4c9;
  background-color: #fff;
}

.page-item:disabled,
.page-link:disabled {
  background-color: #f4f4f4 !important;
}

.circular-button i {
  margin-top: 3px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .pagination-component {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100vw !important;
    padding-left: 10px;
  }

  .pagination-component .show-results {
    margin-top: 10px;
  }

  .pagination-component .dropdown-box {
    width: auto;
  }

  .pagination-component .pagination {
    flex-wrap: wrap;
  }

  .pagination-component .page-item {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.text-bold-dark {
  color: #333;
  font-weight: 500;
}
@media (min-width:200px) and (max-width:900px){
  .pagination-component .dropdown-box {
    padding: 0 10px;
  }
}