@import "../../../../assets/styles/variables.scss";

.basic_info {


table {
    max-width: 45vw;
    
}
  tr {
    height: 45PX;
    vertical-align: middle;
  }

  td{
    width: 50%;
  }
  .fundedViewleftWrap {
    width: 36%;
    border: solid 1px $dark6;
    margin-top: 2rem;
    padding: 20px;
  }
  .label {
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
    font-family: $font-Instrument;
    color: $dark10;
  }
  .value {
    font-size: 11px !important;
    line-height: 16px;
    font-weight: 500 !important;
    font-family: $font-Instrument;
    color: $dark14;
  }
}

.action_svg{
  svg{
  width: 18px;
  height: 18px;
  }
}